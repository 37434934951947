export const glossaryData = [
  {
    letter: "A",
    data: [
      {
        name: "Accelerator",
        description:
          "An accelerator is a programme or organisation that helps early-stage startups grow and expand quickly in exchange for equity by offering coaching, cash, and resources.",
      },
      {
        name: "API (Application Programming Interface)",
        description:
          "An API is a set of rules and protocols that allows different software applications to communicate with each other. It defines the methods and data formats that applications can use to request and exchange information.",
      },
      {
        name: "Agile",
        description:
          "Agile is a project management and software development approach that emphasizes flexibility, collaboration, and customer responsiveness. It involves iterative and incremental development to adapt to changing requirements.",
      },
      {
        name: "Artificial Intelligence (AI)",
        description:
          "Artificial Intelligence refers to the simulation of human intelligence in machines that can perform tasks that typically require human intelligence, such as visual perception, speech recognition, and decision-making.",
      },
      {
        name: "Algorithm",
        description:
          "An algorithm is a step-by-step procedure or set of rules for solving a specific problem or performing a particular task. It is commonly used in computer science and mathematics.",
      },
      {
        name: "Analytics",
        description:
          "Analytics involves the discovery, interpretation, and communication of meaningful patterns and trends in data. It is often used in business to make data-driven decisions.",
      },
      {
        name: "Authentication",
        description:
          "Authentication is the process of verifying the identity of a user or system. It ensures that the entity trying to access a resource is who they claim to be.",
      },
      {
        name: "Azure",
        description:
          "Azure is a cloud computing platform and service offered by Microsoft. It provides various cloud-based services, including computing, storage, and networking resources.",
      },
      {
        name: "Augmented Reality (AR)",
        description:
          "Augmented Reality is a technology that overlays digital information, such as images, videos, or 3D models, onto the real world. It is often used in mobile apps and headsets to enhance the user's perception of reality.",
      },
      {
        name: "Automation",
        description:
          "Automation is the use of technology and machinery to perform tasks with minimal human intervention. It is commonly used to increase efficiency and reduce manual labor.",
      },
    ],
  },
  {
    letter: "B",
    data: [
      {
        name: "Bootstrap",
        description:
          "Bootstrap is a front-end framework that allows developers to quickly create responsive and attractive web designs using pre-built CSS and JavaScript components.",
      },
      {
        name: "Blockchain",
        description:
          "Blockchain is a distributed ledger technology that records transactions across multiple computers, providing transparency and security for various applications, including cryptocurrencies.",
      },
      {
        name: "Big Data",
        description:
          "Big Data refers to vast and complex data sets that are difficult to process using traditional data management tools, often requiring specialized technologies and techniques for analysis.",
      },
      {
        name: "Bitcoin",
        description:
          "Bitcoin is a decentralized digital currency that operates on a blockchain, allowing peer-to-peer transactions without the need for intermediaries like banks.",
      },
      {
        name: "Business Model",
        description:
          "A business model defines how a company creates, delivers, and captures value. It includes revenue sources, customer segments, and distribution channels.",
      },
      {
        name: "Burn Rate",
        description:
          "Burn rate represents the rate at which a company is spending its capital or funds. It is often used to assess financial sustainability.",
      },
      {
        name: "B2B (Business-to-Business)",
        description:
          "B2B refers to transactions and interactions between businesses or organizations, as opposed to those between businesses and individual consumers (B2C).",
      },
      {
        name: "B2C (Business-to-Consumer)",
        description:
          "B2C pertains to business activities focused on selling products or services directly to individual consumers rather than other businesses.",
      },
      {
        name: "Brand Identity",
        description:
          "Brand identity encompasses the visual and emotional elements that represent a company, including logos, design, messaging, and values that distinguish it from competitors.",
      },
      {
        name: "Back-End Development",
        description:
          "Back-end development involves building and maintaining server-side components of a software application, responsible for data storage, processing, and server-side logic.",
      },
    ],
  },
  {
    letter: "C",
    data: [
      {
        name: "Cryptocurrency",
        description:
          "Cryptocurrency is a digital or virtual form of currency that uses cryptography for security. It operates independently of a central authority, such as a government or a bank.",
      },
      {
        name: "Cloud Computing",
        description:
          "Cloud computing is the delivery of computing services, including servers, storage, databases, networking, software, analytics, and more, over the internet (the cloud) to offer faster innovation, flexible resources, and economies of scale.",
      },
      {
        name: "Cybersecurity",
        description:
          "Cybersecurity refers to the practice of protecting computer systems, networks, and data from theft, damage, or unauthorized access. It involves various technologies and processes to safeguard digital information.",
      },
      {
        name: "Content Marketing",
        description:
          "Content marketing is a strategy that involves creating and distributing valuable, relevant, and consistent content to attract and engage a target audience, ultimately driving profitable customer action.",
      },
      {
        name: "CRM (Customer Relationship Management)",
        description:
          "CRM is a system for managing a company's interactions with current and potential customers. It often involves the use of technology to organize, automate, and synchronize sales, marketing, and customer service.",
      },
      {
        name: "Crowdsourcing",
        description:
          "Crowdsourcing is a process of obtaining input, ideas, services, or content by soliciting contributions from a large group of people, typically via the internet and social media.",
      },
      {
        name: "Data Analytics",
        description:
          "Data analytics involves the use of techniques and tools to analyze and interpret data, often with the goal of gaining insights, making data-driven decisions, and solving complex problems.",
      },
      {
        name: "Blockchain",
        description:
          "Blockchain is a decentralized and distributed ledger technology used for recording transactions across multiple computers. It is most commonly associated with cryptocurrencies and provides transparency and security.",
      },
      {
        name: "Conversion Rate",
        description:
          "Conversion rate is a metric used in marketing and e-commerce to measure the percentage of visitors who take a desired action, such as making a purchase or signing up for a newsletter.",
      },
      {
        name: "Crowdfunding",
        description:
          "Crowdfunding is a method of raising capital or funds from a large number of people, typically through online platforms, for various projects, startups, or charitable causes.",
      },
    ],
  },
];
