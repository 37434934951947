import slide1 from "../assets/images/blogs/Defi.png";
import slide2 from "../assets/images/blogs/Blockchain.png";
import slide3 from "../assets/images/blogs/Web3.png";


export const blogsListData = [
  {
    name: "DECODING DEFI - FUTURE OF FINANCE.",
    description:
      "Decentralized Finance, or DeFi, is a new and innovative financial system that operates on a decentralized, open-source blockchain network. In this system, financial products and services are accessible to anyone with an internet connection, without the need for intermediaries such as banks or financial institutions. With DeFi, you can do most of the things— earn interest, borrow, lend, buy insurance, trade derivatives, trade assets, and more — but it is faster and doesn’t require paperwork or a third party. DeFi is built on blockchain technology (blockchain technology keeps data immutable and visible. No one can manipulate or change it without the rest of the peer-to-peer network noticing), which provides a transparent and secure platform for transactions. Anyone with an internet connection can access a DeFi platform and transactions occur without any geographic restriction. In the DeFi ecosystem, the rules and protocols are governed by smart contracts, which are self-executing contracts that are transparent, immutable, and tamper-proof. DeFi works on a peer-to-peer (P2P) basis. P2P (peer-to-peer) refers to the direct interaction between two parties to exchange value, such as cryptocurrencies or other assets, directly with each other without the need for a centralized exchange or financial institution.",
    imageSrc:
      slide1,
    imageAlt: "DECODING DEFI - FUTURE OF FINANCE.",
    href: "DECODING-DEFI-FUTURE-OF-FINANCE",
  },
  {
    name: `Blockchain Technology - the answer to all the "why". `,
    description:
      "A blockchain is a decentralised ledger that consists of continuously expanding lists of records (blocks) that are safely connected to one another by cryptographic hashes. Each block has a list of transactions, nonce value, time stamp, hash, and previous hash. All transactions or data stored on the blockchain are visible to all participants in the network. This transparency ensures accountability and reduces the need for trust between parties. They basically form a chain with each new block linked to the ones before it. Blockchain transactions are irreversible because, once they've been made, the data in any specific block can't be changed backwards. The information stored in a blockchain is not controlled by any central authority but is distributed among a network of computers, known as nodes, which collectively maintain the integrity and security of the system. Each node stores a copy of the entire blockchain, ensuring redundancy and fault tolerance. The process of adding new blocks to the blockchain is called mining, where nodes in the network compete to solve complex mathematical problems to validate new transactions and add them to the blockchain. ",
    imageSrc:
      slide2,
    imageAlt: `Blockchain Technology - the answer to all the "why". `,
    href: "Blockchain-Technology-the-answer-to-all-the-why",
  },
  {
    name: "Emergence of Web3 in our childhood",
    description: `Web3.0, often known as Web3, is a planned evolution of the World Wide Web that aims to make it more intelligent, interconnected, and practical. It is also known as the "Semantic Web" or the "Intelligent Web". Web3 is built upon open protocols that value transparency and innovation. The goal of Web3.0 is to establish a decentralized Web that is supported by blockchain technology and artificial intelligence, as opposed to Web 1.0 and Web 2.0, which is concentrated on the presentation and sharing of information. The application of Web3.0  Technology enables the internet to be more personalized, accurate and intelligent. Web3 puts power in the hands of individuals rather than corporations. Let's look at how we got here before we discuss Web3. `,
    imageSrc:
      slide3,
    imageAlt: "Emergence of Web3 in our childhood",
    href: "Emergence-of-Web3-in-our-childhood",
  },
];
